import React from "react"
import Img from "gatsby-image"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import RichTextRenderer from "../RichTextRenderer"

const OurStory = ({
  data: { pictureCollage, storyTitle, storyDescription },
}) => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const { imageOne, imageTwo } = node.data.target.fields
        return (
          <div className="max-w-6xl mx-auto md:flex my-6">
            <div>
              <img
                src={imageOne["en-US"].fields.file["en-US"].url}
                alt={imageOne["en-US"].fields.title["en-US"]}
              />
            </div>
            <div className="md:ml-4 mt-2 md:mt-0">
              <img
                src={imageTwo["en-US"].fields.file["en-US"].url}
                alt={imageTwo["en-US"].fields.title["en-US"]}
              />
            </div>
          </div>
        )
      },
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="lg:w-4/5 my-4">{children}</p>
      ),
      [INLINES.HYPERLINK]: node => {
        const { content, data } = node
        return (
          <a
            className="cursor-pointer text-blue-500 hover:underline hover:text-blue-700"
            href={data.uri}
            target="_blank"
            rel="noreferrer"
          >
            {content[0].value}
          </a>
        )
      },
    },
  }
  return (
    <div className="companyStory md:mt-8">
      <div className="md:flex collage">
        <Img
          fluid={pictureCollage[0].fluid}
          fade={false}
          loading="eager"
          className="img md:w-1/2"
        />
        <Img
          fluid={pictureCollage[1].fluid}
          fade={false}
          loading="eager"
          className="img md:w-1/2"
        />
      </div>

      <div className="my-8 mx-auto">
        <div className="max-w-6xl mx-auto px-4 md:px-8 xl:px-0">
          <h3 className="font-bold text-white text-3xl md:text-4xl uppercase siteFont">
            {storyTitle}
          </h3>
          <div className="Gray my-4 md:text-lg">
            {RichTextRenderer(storyDescription.json, options)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurStory
