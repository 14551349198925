import React, { useContext } from "react"
import Img from "gatsby-image"

// Background Image component
import Background from "../backgroundImage"

const Banner = ({
  data: { bannerTitle, bannerImage, valuesList, missionTitle, missionValue },
  customNav,
}) => {
  return (
    <Background className={customNav ? "pt-8 md:pt-16" : ""}>
      <div className="max-w-6xl px-4 mx-auto md:px-8 xl:px-0">
        <div className="flex flex-col grid-flow-col gap-4 md:grid ">
          <div className="order-2 row-span-2 md:order-none">
            <h1 className="text-3xl font-bold text-white uppercase md:pt-12 md:text-4xl xl:text-5xl siteFont">
              {bannerTitle}
            </h1>
            <div className="py-4 font-bold text-white">
              <h3 className="my-3 text-2xl uppercase md:text-4xl siteFont">
                Our values
              </h3>
              <div>
                {valuesList.map((value, index) => (
                  <div key={index} className="md:flex md:pb-8">
                    <div className="w-24 my-4 md:mr-4 md:my-0">
                      <img src={value.image.fluid.src} alt={value.title} />
                    </div>
                    <div className="md:w-3/4">
                      <h3 className="my-1 text-2xl font-bold text-white md:text-3xl siteFont">
                        {value.title}
                      </h3>
                      <p className="font-normal Gray md:text-lg">
                        {value.description.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="order-1 w-3/5 row-span-1 mt-12 mr-8 md:order-none md:w-full md:p-0">
            <Img
              fluid={bannerImage.fluid}
              alt={bannerImage.title}
              fade={false}
              loading="eager"
            />
          </div>

          <Background
            type="blue"
            className="order-3 hidden row-span-1 p-4 font-bold text-center text-white md:block blueBg md:h-56 lg:h-48 md:mt-6 md:order-none md:rounded-lg md:text-left"
          >
            <h3 className="my-3 text-3xl uppercase md:text-4xl siteFont">
              {missionTitle}
            </h3>
            <p className="text-lg md:text-xl">{missionValue}</p>
          </Background>
        </div>
      </div>
      {/* Mobile view */}
      <Background
        type="blue"
        className="order-3 row-span-1 p-4 font-bold text-center text-white md:hidden md:h-56 md:mt-6 md:order-none md:rounded-lg md:text-left"
      >
        <h3 className="my-3 text-3xl uppercase md:text-4xl siteFont">
          {missionTitle}
        </h3>
        <p className="text-lg md:text-xl">{missionValue}</p>
      </Background>
    </Background>
  )
}

export default Banner
