import React, { useContext } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/OurCompany/banner"
import Story from "../components/OurCompany/story"
import Team from "../components/OurCompany/team"
import ContactUs from "../components/contactUs"

// Context API data
import { AppContext } from "../store/AppContext"

const OurCompany = ({ data }) => {
  const globalData = useContext(AppContext)

  const {
    ogTitle,
    ogDescription,
    bannerTitle,
    bannerImage,
    valuesList,
    missionTitle,
    missionValue,
    pictureCollage,
    storyTitle,
    storyDescription,
    teamMembers,
  } = data.company

  const bannerData = {
    bannerTitle,
    bannerImage,
    valuesList,
    missionTitle,
    missionValue,
  }
  const teamData = { pictureCollage, storyTitle, storyDescription }
  return (
    <Layout>
      <SEO title={ogTitle || "Our Company"} description={ogDescription} />
      <Banner data={bannerData} customNav={globalData?.state?.customNav} />
      <Story data={teamData} />
      <Team data={teamMembers} />
      <ContactUs simple />
    </Layout>
  )
}
export const query = graphql`
  query OurCompanyQuery {
    company: contentfulOurCompanyPage {
      ogTitle
      ogDescription
      bannerImage {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      bannerTitle
      valuesList {
        id
        title
        description {
          description
        }
        image {
          title
          fluid {
            src
          }
        }
      }
      missionTitle
      missionValue
      pictureCollage {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      storyTitle
      storyDescription {
        json
      }
      teamMembers {
        id
        name
        designationTitle
        bio {
          json
        }
        image {
          title
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        imageHover {
          title
          fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export default OurCompany
