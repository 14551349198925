import React, { useState } from "react"
import Img from "gatsby-image"

// Components
import Modal from "../modal"
import TeamModal from "./teamModal"

const Team = ({ data }) => {
  const [modal, setModal] = useState(false)
  const [selectedMember, setSelectedMember] = useState(null)

  const showDetails = data => {
    setSelectedMember(data)
    toggle(true)
  }
  const toggle = val => {
    setModal(val)
  }
  return (
    <div className="max-w-6xl py-6 mx-auto md:px-8 xl:px-0">
      <h3 className="my-4 text-4xl font-bold text-center text-white uppercase siteFont">
        Meet the team
      </h3>
      {modal && (
        <Modal close={toggle}>
          <TeamModal toggle={toggle} data={selectedMember} />
        </Modal>
      )}
      <div className="flex flex-wrap items-center justify-center team">
        {data.map(member => {
          return (
            <div
              key={member.id}
              className="flex flex-col items-center justify-between w-1/2 px-1 member md:w-1/3 xl:w-1/4 md:mx-0"
            >
              <div
                className="relative w-full"
                onClick={() => showDetails(member)}
              >
                <div className="memberBlock">
                  <Img
                    fluid={member.image.fluid}
                    alt={member.image.title}
                    className="member"
                  />
                  <Img
                    fluid={member.imageHover.fluid}
                    alt={member.imageHover.title}
                    fadeIn={false}
                    loading="eager"
                    className="memberHover"
                  />
                </div>
                <div className="flex flex-col items-center justify-center moreInfo">
                  <button className="w-6 h-auto mb-1 text-white border border-gray-600 rounded-full md:mx-auto focus:outline-none md:w-8 md:text-xl">
                    +
                  </button>
                  <p className="text-xs text-gray-600 uppercase">More info</p>
                </div>
              </div>
              <div
                className="py-2 text-center text-white"
                onClick={() => showDetails(member)}
              >
                <p className="font-bold md:text-lg">{member.name}</p>
                <p className="text-xs uppercase">{member.designationTitle}</p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default Team
