import React from "react"
import Img from "gatsby-image"

import RichTextRenderer from "../RichTextRenderer"
import { CloseIcon } from "../icons"

const TeamModal = ({
  toggle,
  data: { name, designationTitle, image, bio },
}) => {
  return (
    <>
      <div className="relative p-4 overflow-y-scroll text-white md:flex md:py-8 md:px-4 custom-scroll">
        <div className="top-0 w-1/2 md:sticky md:w-1/3">
          <Img fluid={image.fluid} alt={image.title} />
        </div>
        <div className="w-full h-full md:w-3/4">
          <h1 className="text-2xl font-bold siteFont">{name}</h1>
          <p className="my-2 text-sm tracking-wider uppercase">
            {designationTitle}
          </p>

          <div className="Gray md:text-xl">{RichTextRenderer(bio.json)}</div>
        </div>
      </div>
      <div
        className="absolute p-2 transition-all duration-300 bg-white rounded-full cursor-pointer top-1/50 md:top-1/20 right-1/30 hover:bg-gray-300"
        onClick={() => toggle(false)}
      >
        <CloseIcon color="#000" width={15} height={15} />
      </div>
    </>
  )
}

export default TeamModal
